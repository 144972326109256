"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const LogMessageType_1 = require("@/common/logs/LogMessageType");
const LogMessageDataType_1 = require("@/common/logs/LogMessageDataType");
const TileType_1 = require("@/common/TileType");
const Log_1 = require("@/common/logs/Log");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const cardTypeToCss = {
    event: 'background-color-events',
    corporation: 'background-color-global-event',
    active: 'background-color-active',
    automated: 'background-color-automated',
    prelude: 'background-color-prelude',
    ceo: 'background-color-ceo',
    standard_project: 'background-color-standard-project',
    standard_action: 'background-color-standard-project',
    proxy: undefined,
};
exports.default = vue_1.default.extend({
    name: 'LogMessageComponent',
    props: {
        message: {
            type: Object,
        },
        players: {
            type: Array,
        },
    },
    methods: {
        cardToHtml(card, attrs) {
            const suffixFreeCardName = card.name.split(':')[0];
            const className = cardTypeToCss[card.type];
            if (className === undefined) {
                return suffixFreeCardName;
            }
            let tagHTML = '';
            if (attrs?.tags === true) {
                tagHTML = '&nbsp;' + (card.tags.map((tag) => `<div class="log-tag tag-${tag}"></div>`).join(' '));
            }
            let costHTML = '';
            if (attrs?.cost === true) {
                costHTML = `<span>&nbsp;<div class="log-resource-megacredits">${card.cost}</div></span>`;
            }
            return '<span class="log-card ' + className + '">' + this.$t(suffixFreeCardName) + tagHTML + costHTML + '</span>';
        },
        messageDataToHTML(data) {
            if (data.type === undefined || data.value === undefined) {
                return '';
            }
            switch (data.type) {
                case LogMessageDataType_1.LogMessageDataType.PLAYER:
                    for (const player of this.players) {
                        if (data.value === player.color) {
                            return '<span class="log-player player_bg_color_' + player.color + '">' + player.name + '</span>';
                        }
                    }
                    break;
                case LogMessageDataType_1.LogMessageDataType.CARD:
                    const card = (0, ClientCardManifest_1.getCard)(data.value);
                    if (card !== undefined) {
                        return this.cardToHtml(card, data.attrs);
                    }
                    else {
                        console.log(`Cannot render ${data.value}`);
                    }
                    break;
                case LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT:
                    return '<span class="log-card background-color-global-event">' + this.$t(data.value) + '</span>';
                case LogMessageDataType_1.LogMessageDataType.TILE_TYPE:
                    return this.$t(TileType_1.tileTypeToString[data.value]);
                case LogMessageDataType_1.LogMessageDataType.COLONY:
                    return '<span class="log-card background-color-colony">' + this.$t(data.value) + '</span>';
                default:
                    if (data.type !== LogMessageDataType_1.LogMessageDataType.RAW_STRING) {
                        return this.$t(data.value);
                    }
            }
            return data.value.toString();
        },
        safeMessage(message) {
            try {
                if (message === undefined) {
                    return 'undefined';
                }
                if (message.data === undefined) {
                    return `BUG: Unparseable message: ${message.message}`;
                }
                const data = message.data.map((datum) => {
                    return (datum === undefined) ?
                        'undefined' :
                        ('(' + datum.type + ') ' + datum.value);
                });
                return `BUG: Unparseable message: ${message.message}, (${data.join(', ')})`;
            }
            catch (err) {
                return `BUG: Unparseable message: ${message.message} ${String(err)}`;
            }
        },
        messageToHTML(message) {
            try {
                let logEntryBullet = '';
                if (message.type !== LogMessageType_1.LogMessageType.NEW_GENERATION) {
                    const when = new Date(message.timestamp).toLocaleString();
                    const icon = message.playerId === undefined ? '&#x1f551;' : '&#x1f4ac;';
                    logEntryBullet = `<span title="${when}">${icon}</span>`;
                }
                if (message.message !== undefined) {
                    message.message = this.$t(message.message);
                    return logEntryBullet + Log_1.Log.applyData(message, this.messageDataToHTML);
                }
            }
            catch (err) {
                console.log(err);
                return this.safeMessage(message);
            }
            return '';
        },
    },
});
