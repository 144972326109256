"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants_1 = require("@/common/constants");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'language-switcher',
    methods: {
        reloadWindow() {
            window.location.reload();
        },
        switchLanguageTo(langId) {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('lang', langId);
            this.reloadWindow();
        },
        title(key) {
            const lang = constants_1.LANGUAGES[key];
            return `${lang[0]} (${lang[1]})`;
        },
    },
    computed: {
        ALL_LANGUAGES() {
            return constants_1.ALL_LANGUAGES;
        },
        LANGUAGES() {
            return constants_1.LANGUAGES;
        },
    },
});
