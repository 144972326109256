"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const TagCount_vue_1 = require("@/client/components/TagCount.vue");
const Tag_1 = require("@/common/cards/Tag");
const SpecialTags_1 = require("@/client/cards/SpecialTags");
const PlayerTagDiscount_vue_1 = require("@/client/components/overview/PlayerTagDiscount.vue");
const PointsPerTag_vue_1 = require("@/client/components/overview/PointsPerTag.vue");
const PartyName_1 = require("@/common/turmoil/PartyName");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const vueRoot_1 = require("@/client/components/vueRoot");
const CardName_1 = require("@/common/cards/CardName");
const ORDER = [
    Tag_1.Tag.BUILDING,
    Tag_1.Tag.SPACE,
    Tag_1.Tag.SCIENCE,
    Tag_1.Tag.POWER,
    Tag_1.Tag.EARTH,
    Tag_1.Tag.JOVIAN,
    Tag_1.Tag.VENUS,
    Tag_1.Tag.PLANT,
    Tag_1.Tag.MICROBE,
    Tag_1.Tag.ANIMAL,
    Tag_1.Tag.CITY,
    Tag_1.Tag.MOON,
    Tag_1.Tag.MARS,
    'separator',
    Tag_1.Tag.EVENT,
    SpecialTags_1.SpecialTags.NONE,
    Tag_1.Tag.WILD,
    SpecialTags_1.SpecialTags.INFLUENCE,
    SpecialTags_1.SpecialTags.CITY_COUNT,
    SpecialTags_1.SpecialTags.COLONY_COUNT,
    SpecialTags_1.SpecialTags.EXCAVATIONS,
    SpecialTags_1.SpecialTags.CORRUPTION,
];
const isInGame = (tag, game) => {
    const gameOptions = game.gameOptions;
    if (game.turmoil === undefined && tag === SpecialTags_1.SpecialTags.INFLUENCE)
        return false;
    switch (tag) {
        case SpecialTags_1.SpecialTags.COLONY_COUNT:
            return gameOptions.coloniesExtension !== false;
        case SpecialTags_1.SpecialTags.INFLUENCE:
            return game.turmoil !== undefined;
        case SpecialTags_1.SpecialTags.EXCAVATIONS:
        case SpecialTags_1.SpecialTags.CORRUPTION:
            return gameOptions.underworldExpansion !== false;
        case Tag_1.Tag.VENUS:
            return game.gameOptions.venusNextExtension !== false;
        case Tag_1.Tag.MOON:
            return game.gameOptions.moonExpansion !== false;
        case Tag_1.Tag.MARS:
            return (gameOptions.pathfindersExpansion || gameOptions.underworldExpansion);
    }
    return true;
};
const getTagCount = (tagName, player) => {
    switch (tagName) {
        case SpecialTags_1.SpecialTags.COLONY_COUNT:
            return player.coloniesCount || 0;
        case SpecialTags_1.SpecialTags.INFLUENCE:
            return player.influence || 0;
        case SpecialTags_1.SpecialTags.CITY_COUNT:
            return player.citiesCount || 0;
        case SpecialTags_1.SpecialTags.NONE:
            return player.noTagsCount || 0;
        case SpecialTags_1.SpecialTags.EXCAVATIONS:
            return player.excavations;
        case SpecialTags_1.SpecialTags.CORRUPTION:
            return player.corruption;
    }
    return player.tags.find((tag) => tag.tag === tagName)?.count ?? 0;
};
exports.default = vue_1.default.extend({
    name: 'PlayerTags',
    props: {
        playerView: {
            type: Object,
        },
        player: {
            type: Object,
        },
        hideZeroTags: {
            type: Boolean,
        },
        isTopBar: {
            type: Boolean,
            default: false,
        },
        conciseTagsViewDefaultValue: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        const x = ORDER.map((key) => [key, { name: key, discount: 0, points: 0, count: getTagCount(key, this.player), halfPoints: 0 }]);
        const details = Object.fromEntries(x);
        details['all'] = { name: 'all', discount: this.player?.cardDiscount ?? 0, points: 0, count: 0, halfPoints: 0 };
        for (const card of this.player.tableau) {
            for (const discount of card.discount ?? []) {
                const tag = discount.tag ?? 'all';
                details[tag].discount += discount.amount;
            }
            if (card.name === CardName_1.CardName.CULTIVATION_OF_VENUS || card.name === CardName_1.CardName.VENERA_BASE) {
                details[Tag_1.Tag.VENUS].halfPoints++;
            }
            else {
                const vps = (0, ClientCardManifest_1.getCard)(card.name)?.victoryPoints;
                if (vps !== undefined && typeof (vps) !== 'number' && vps !== 'special' && vps.tag !== undefined) {
                    details[vps.tag].points += ((vps.each ?? 1) / (vps.per ?? 1));
                }
            }
        }
        if (this.playerView.game.turmoil?.ruling === PartyName_1.PartyName.UNITY &&
            this.playerView.game.turmoil.politicalAgendas?.unity.policyId === 'up04') {
            details[Tag_1.Tag.SPACE].discount += 2;
        }
        const tagsInOrder = [];
        for (const tag of ORDER) {
            const entry = details[tag];
            tagsInOrder.push(entry);
        }
        return {
            all: details['all'],
            tagsInOrder,
        };
    },
    components: {
        'tag-count': TagCount_vue_1.default,
        PlayerTagDiscount: PlayerTagDiscount_vue_1.default,
        PointsPerTag: PointsPerTag_vue_1.default,
    },
    computed: {
        isThisPlayer() {
            return this.player.color === this.playerView.thisPlayer?.color;
        },
        cardsInHandCount() {
            return this.player.cardsInHandNbr ?? 0;
        },
        hideVpCount() {
            return !this.playerView.game.gameOptions.showOtherPlayersVP && !this.isThisPlayer;
        },
        isEscapeVelocityOn() {
            return this.playerView.game.gameOptions.escapeVelocityMode;
        },
        escapeVelocityPenalty() {
            return this.player.victoryPointsBreakdown.escapeVelocity;
        },
        tooltipCss() {
            return 'tooltip tooltip-' + (this.isTopBar ? 'bottom' : 'top');
        },
        tags() {
            const concise = (0, vueRoot_1.vueRoot)(this).componentsVisibility?.['tags_concise'] ?? this.conciseTagsViewDefaultValue;
            return this.tagsInOrder.filter((entry) => {
                if (!isInGame(entry.name, this.playerView.game)) {
                    return false;
                }
                if (entry.count === 0 && entry.discount === 0) {
                    if (this.hideZeroTags || concise) {
                        return false;
                    }
                }
                return true;
            });
        },
    },
});
