"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardResource = void 0;
var CardResource;
(function (CardResource) {
    CardResource["ANIMAL"] = "Animal";
    CardResource["MICROBE"] = "Microbe";
    CardResource["FIGHTER"] = "Fighter";
    CardResource["SCIENCE"] = "Science";
    CardResource["FLOATER"] = "Floater";
    CardResource["ASTEROID"] = "Asteroid";
    CardResource["CAMP"] = "Camp";
    CardResource["PRESERVATION"] = "Preservation";
    CardResource["DIRECTOR"] = "Director";
    CardResource["DISEASE"] = "Disease";
    CardResource["GRAPHENE"] = "Graphene";
    CardResource["HYDROELECTRIC_RESOURCE"] = "Hydroelectric resource";
    CardResource["RESOURCE_CUBE"] = "Resource cube";
    CardResource["DATA"] = "Data";
    CardResource["SYNDICATE_FLEET"] = "Syndicate Fleet";
    CardResource["VENUSIAN_HABITAT"] = "Venusian Habitat";
    CardResource["SPECIALIZED_ROBOT"] = "Specialized Robot";
    CardResource["SEED"] = "Seed";
    CardResource["AGENDA"] = "Agenda";
    CardResource["ORBITAL"] = "Orbital";
    CardResource["CLONE_TROOPER"] = "Clone Trooper";
    CardResource["TOOL"] = "Tool";
    CardResource["WARE"] = "Ware";
    CardResource["SCOOP"] = "Scoop";
    CardResource["JOURNALISM"] = "Journalism";
    CardResource["ACTIVIST"] = "Activist";
    CardResource["SUPPLY_CHAIN"] = "Supply Chain";
})(CardResource = exports.CardResource || (exports.CardResource = {}));
