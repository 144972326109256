"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.milestoneNames = void 0;
exports.milestoneNames = [
    'Terraformer',
    'Mayor',
    'Gardener',
    'Planner',
    'Builder',
    'Generalist',
    'Specialist',
    'Ecologist',
    'Tycoon',
    'Legend',
    'Diversifier',
    'Tactician',
    'Polar Explorer',
    'Energizer',
    'Rim Settler',
    'Hoverlord',
    'Networker',
    'One Giant Step',
    'Lunarchitect',
    'Colonizer',
    'Farmer',
    'Minimalist',
    'Terran',
    'Tropicalist',
    'Economizer',
    'Pioneer',
    'Land Specialist',
    'Martian',
    'Businessperson',
    'Collector',
    'Firestarter',
    'Terra Pioneer',
    'Spacefarer',
    'Gambler',
    'Electrician',
    'Smith',
    'Tradesman',
    'Irrigator',
    'Capitalist',
    'Tunneler',
    'Risktaker',
];
