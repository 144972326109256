"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'ShiftAresGlobalParameters',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        const hazardData = this.playerinput.aresData.hazardData;
        return {
            hazardData: hazardData,
            lowOceanDelta: 0,
            highOceanDelta: 0,
            temperatureDelta: 0,
            oxygenDelta: 0,
        };
    },
    methods: {
        saveData() {
            const response = {
                lowOceanDelta: this.lowOceanDelta,
                highOceanDelta: this.highOceanDelta,
                temperatureDelta: this.temperatureDelta,
                oxygenDelta: this.oxygenDelta,
            };
            this.onsave({ type: 'aresGlobalParameters', response });
        },
    },
    computed: {
        ADJUSTMENT_RANGE() {
            return [-1, 0, 1];
        },
    },
});
