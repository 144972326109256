"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Phase_1 = require("@/common/Phase");
exports.default = vue_1.default.extend({
    name: 'GameOverview',
    data() {
        return {};
    },
    props: {
        status: {
            type: String,
        },
        game: {
            type: Object,
        },
        id: {
            type: String,
        },
    },
    methods: {
        isRunning() {
            return this.game?.phase !== Phase_1.Phase.END;
        },
    },
});
