"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i18n_1 = require("@/client/directives/i18n");
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'PurgeWarning',
    props: {
        expectedPurgeTimeMs: {
            type: Number,
        },
    },
    computed: {
        hoursLeft() {
            const nowMs = Date.now();
            const diffMs = this.expectedPurgeTimeMs - nowMs;
            const diffh = diffMs / 3600000;
            return diffh;
        },
        purgeTime() {
            const date = new Date(this.expectedPurgeTimeMs);
            function pad(n) {
                return ('0' + n).slice(-2);
            }
            return '' + date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes());
        },
        soon() {
            return this.hoursLeft < 48;
        },
        klass() {
            return this.soon ? 'general-warning' : '';
        },
        warningText() {
            if (this.soon) {
                return (0, i18n_1.translateTextWithParams)('Warning: This game will be purged in approximately ${0} hours.', [Math.floor(this.hoursLeft).toString()]);
            }
            else {
                return (0, i18n_1.translateTextWithParams)('Warning: This server automatically purges unfinished games. Try to complete this game by ${0}.', [this.purgeTime]);
            }
        },
    },
    methods: {},
});
