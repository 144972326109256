import { render, staticRenderFns } from "./VictoryPointChart.vue?vue&type=template&id=415dd1ed"
import script from "./VictoryPointChart.vue?vue&type=script&lang=ts"
export * from "./VictoryPointChart.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports