"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardType_1 = require("@/common/cards/CardType");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const ClientGlobalEventManifest_1 = require("@/client/turmoil/ClientGlobalEventManifest");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const AllColonies_1 = require("@/common/colonies/AllColonies");
const GameModule_1 = require("@/common/cards/GameModule");
const Tag_1 = require("@/common/cards/Tag");
const ClientColonyManifest_1 = require("@/client/colonies/ClientColonyManifest");
const i18n_1 = require("@/client/directives/i18n");
const MilestoneName_1 = require("@/common/ma/MilestoneName");
const AwardName_1 = require("@/common/ma/AwardName");
const CardListSearchIndex_1 = require("@/client/components/cardlist/CardListSearchIndex");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
const PreferencesIcon_vue_1 = require("@/client/components/PreferencesIcon.vue");
const Milestone_vue_1 = require("@/client/components/Milestone.vue");
const Award_vue_1 = require("@/client/components/Award.vue");
const moduleAbbreviations = {
    base: 'b',
    corpera: 'c',
    prelude: 'p',
    prelude2: '2',
    venus: 'v',
    colonies: 'C',
    turmoil: 't',
    community: '*',
    promo: 'r',
    ares: 'a',
    moon: 'm',
    pathfinders: 'P',
    ceo: 'l',
    starwars: 'w',
    underworld: 'u',
};
const ALL_MODULES = GameModule_1.GAME_MODULES.map((m) => moduleAbbreviations[m]).join('');
exports.default = vue_1.default.extend({
    name: 'card-list',
    components: {
        Card: Card_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
        Colony: Colony_vue_1.default,
        Milestone: Milestone_vue_1.default,
        Award: Award_vue_1.default,
        PreferencesIcon: PreferencesIcon_vue_1.default,
    },
    data() {
        return {
            filterText: decodeURIComponent(window.location.hash).slice(1),
            namesOnly: true,
            expansions: {
                base: true,
                corpera: true,
                prelude: true,
                prelude2: true,
                venus: true,
                colonies: true,
                turmoil: true,
                community: true,
                ares: true,
                moon: true,
                promo: true,
                pathfinders: true,
                ceo: true,
                starwars: true,
                underworld: true,
            },
            types: {
                event: true,
                active: true,
                automated: true,
                prelude: true,
                corporation: true,
                standard_project: true,
                standard_action: false,
                proxy: false,
                globalEvents: true,
                colonyTiles: true,
                milestones: true,
                awards: true,
                ceo: true,
            },
            tags: {
                building: true,
                space: true,
                science: true,
                power: true,
                earth: true,
                jovian: true,
                venus: true,
                plant: true,
                microbe: true,
                animal: true,
                city: true,
                moon: true,
                mars: true,
                wild: true,
                event: true,
                clone: true,
                none: true,
            },
            searchIndex: new CardListSearchIndex_1.CardListSearchIndex(),
            showAdvanced: false,
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const searchString = urlParams.get('search');
        if (searchString) {
            this.filterText = searchString;
        }
        const modules = urlParams.get('m') || ALL_MODULES;
        GameModule_1.GAME_MODULES.forEach((module) => {
            return this.expansions[module] = modules.includes(moduleAbbreviations[module]);
        });
        this.searchIndex.build();
        this.$refs.filter.focus();
    },
    computed: {
        allModules() {
            return GameModule_1.GAME_MODULES;
        },
        MODULE_NAMES() {
            return GameModule_1.MODULE_NAMES;
        },
        allTypes() {
            return [
                CardType_1.CardType.EVENT,
                CardType_1.CardType.ACTIVE,
                CardType_1.CardType.AUTOMATED,
                CardType_1.CardType.PRELUDE,
                CardType_1.CardType.CORPORATION,
                CardType_1.CardType.STANDARD_PROJECT,
                CardType_1.CardType.CEO,
                'colonyTiles',
                'globalEvents',
                'milestones',
                'awards',
            ];
        },
        allTags() {
            const results = [];
            for (const tag in Tag_1.Tag) {
                if (Object.prototype.hasOwnProperty.call(Tag_1.Tag, tag)) {
                    results.push(Tag_1.Tag[tag]);
                }
            }
            return results.concat('none');
        },
        allMilestoneNames() {
            return MilestoneName_1.milestoneNames;
        },
        allAwardNames() {
            return AwardName_1.awardNames;
        },
    },
    watch: {
        filterText: function (val) {
            setTimeout(() => {
                window.location.hash = '#' + encodeURIComponent(val);
            }, 10);
        },
    },
    methods: {
        updateUrl(search) {
            if (window.history.pushState) {
                let url = window.location.protocol + '//' + window.location.host + window.location.pathname;
                if (search) {
                    url = url + '?search=' + search;
                }
                let m = GameModule_1.GAME_MODULES.map((module) => {
                    return this.expansions[module] ? moduleAbbreviations[module] : '';
                }).join('');
                if (m === '')
                    m = '-';
                if (m !== ALL_MODULES) {
                    url = url + '?m=' + m;
                }
                window.history.pushState({ path: url }, '', url);
            }
        },
        invertExpansions() {
            GameModule_1.GAME_MODULES.forEach((module) => this.expansions[module] = !this.expansions[module]);
        },
        invertTags() {
            this.allTags.forEach((tag) => this.tags[tag] = !this.tags[tag]);
        },
        invertTypes() {
            this.allTypes.forEach((type) => this.types[type] = !this.types[type]);
        },
        sort(names) {
            const translated = names.map((name) => ({ name: name, text: (0, i18n_1.translateText)(name) }));
            translated.sort((a, b) => a.text.localeCompare(b.text));
            return translated.map((e) => e.name);
        },
        getAllStandardProjectCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.STANDARD_PROJECT)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllProjectCards() {
            const names = [];
            names.push(...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.AUTOMATED)).map(ClientCardManifest_1.toName));
            names.push(...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.ACTIVE)).map(ClientCardManifest_1.toName));
            names.push(...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.EVENT)).map(ClientCardManifest_1.toName));
            return this.sort(names);
        },
        getAllCorporationCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.CORPORATION)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllPreludeCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.PRELUDE)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllCeoCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.CEO)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllGlobalEvents() {
            return this.sort(Array.from((0, ClientGlobalEventManifest_1.allGlobalEventNames)()));
        },
        getAllColonyNames() {
            return AllColonies_1.OFFICIAL_COLONY_NAMES.concat(AllColonies_1.COMMUNITY_COLONY_NAMES).concat(AllColonies_1.PATHFINDERS_COLONY_NAMES);
        },
        include(name, type) {
            const normalized = this.filterText.toLocaleUpperCase();
            if (normalized.length === 0) {
                return true;
            }
            if (this.namesOnly) {
                return name.toLocaleUpperCase().includes(normalized);
            }
            else {
                return this.searchIndex.matches(this.filterText, type, name);
            }
        },
        expansionIconClass(expansion) {
            if (expansion === 'base')
                return '';
            switch (expansion) {
                case 'corpera': return 'expansion-icon-CE';
                case 'colonies': return 'expansion-icon-colony';
                case 'moon': return 'expansion-icon-themoon';
                default: return `expansion-icon-${expansion}`;
            }
        },
        filterByTags(card) {
            if (card.tags.length === 0) {
                return this.tags['none'] === true;
            }
            let matches = false;
            for (const tag of card.tags) {
                if (this.tags[tag])
                    matches = true;
            }
            return matches;
        },
        showCard(cardName) {
            if (!this.include(cardName, 'card'))
                return false;
            const card = (0, ClientCardManifest_1.getCard)(cardName);
            if (card === undefined) {
                return false;
            }
            if (!this.filterByTags(card))
                return false;
            if (!this.types[card.type])
                return false;
            return this.expansions[card.module] === true;
        },
        showGlobalEvent(name) {
            if (!this.include(name, 'globalEvent'))
                return false;
            const globalEvent = (0, ClientGlobalEventManifest_1.getGlobalEvent)(name);
            return globalEvent !== undefined && this.expansions[globalEvent.module] === true;
        },
        showColony(name) {
            if (!this.include(name, 'colony'))
                return false;
            const colony = (0, ClientColonyManifest_1.getColony)(name);
            return colony !== undefined && this.expansions[colony.module ?? 'base'] === true;
        },
        showMA(name) {
            return this.include(name, 'ma');
        },
        getLanguageCssClass() {
            const language = (0, PreferencesManager_1.getPreferences)().lang;
            return 'language-' + language;
        },
        colonyModel(colonyName) {
            return {
                colonies: [],
                isActive: false,
                name: colonyName,
                trackPosition: 0,
                visitor: undefined,
            };
        },
        milestoneModel(milestoneName) {
            return {
                name: milestoneName,
                playerName: '',
                playerColor: '',
                scores: [],
            };
        },
        awardModel(awardName) {
            return {
                name: awardName,
                playerName: '',
                playerColor: '',
                scores: [],
            };
        },
        experimentalUI() {
            return (0, PreferencesManager_1.getPreferences)().experimental_ui;
        },
        toggleNamesOnly() {
            this.namesOnly = !this.namesOnly;
        },
        toggleAdvancedSearch() {
            this.showAdvanced = !this.showAdvanced;
        },
    },
});
