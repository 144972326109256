"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const MODULE_TO_CSS = {
    corpera: 'corporate-icon',
    promo: 'promo-icon',
    venus: 'venus-icon',
    colonies: 'colonies-icon',
    prelude: 'prelude-icon',
    prelude2: 'prelude2-icon',
    turmoil: 'turmoil-icon',
    community: 'community-icon',
    ares: 'ares-icon',
    moon: 'moon-icon',
    pathfinders: 'pathfinders-icon',
    ceo: 'ceo-icon',
    starwars: 'starwars-icon',
    underworld: 'underworld-icon',
};
exports.default = vue_1.default.extend({
    name: 'CardExpansion',
    props: {
        expansion: {
            type: String,
            required: true,
        },
        isCorporation: {
            type: Boolean,
            required: true,
        },
        isResourceCard: {
            type: Boolean,
            required: true,
        },
        compatibility: {
            type: (Array),
            required: false,
        },
    },
    methods: {
        modules() {
            return this.compatibility.filter((e) => e !== this.expansion);
        },
        iconClasses(module) {
            const classes = ['card-expansion', 'project-icon'];
            if (module !== 'base') {
                classes.push(MODULE_TO_CSS[module]);
            }
            return classes.join(' ');
        },
        templateClasses() {
            if (this.isCorporation) {
                return 'card-corporation-expansion';
            }
            else {
                if (this.isResourceCard) {
                    return 'resource-card-icon-expansion-container';
                }
                else {
                    return 'project-icon-expansion-container';
                }
            }
        },
    },
});
