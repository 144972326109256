"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const WarningsComponent_vue_1 = require("./WarningsComponent.vue");
exports.default = vue_1.default.extend({
    name: 'select-option',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
        WarningsComponent: WarningsComponent_vue_1.default,
    },
    methods: {
        saveData() {
            this.onsave({ type: 'option' });
        },
    },
});
