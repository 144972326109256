"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Tag_1 = require("@/common/cards/Tag");
exports.default = vue_1.default.extend({
    name: 'CardTag',
    props: {
        index: {
            type: Number,
            required: true,
            validator: (i) => i < 4,
        },
        type: {
            type: String,
            required: true,
            validator: (type) => type === 'asterisk' || Object.values(Tag_1.Tag).includes(type),
        },
    },
    methods: {
        getClasses() {
            const classes = ['card-tag'];
            classes.push(`tag-${this.type.toLocaleLowerCase()}`);
            return classes.join(' ');
        },
    },
});
