"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const SpaceBonus_1 = require("@/common/boards/SpaceBonus");
const css = {
    [SpaceBonus_1.SpaceBonus.TITANIUM]: 'titanium',
    [SpaceBonus_1.SpaceBonus.STEEL]: 'steel',
    [SpaceBonus_1.SpaceBonus.PLANT]: 'plant',
    [SpaceBonus_1.SpaceBonus.DRAW_CARD]: 'card',
    [SpaceBonus_1.SpaceBonus.HEAT]: 'heat',
    [SpaceBonus_1.SpaceBonus.OCEAN]: 'bonusocean',
    [SpaceBonus_1.SpaceBonus.MEGACREDITS]: '',
    [SpaceBonus_1.SpaceBonus.ANIMAL]: 'animal',
    [SpaceBonus_1.SpaceBonus.MICROBE]: 'microbe',
    [SpaceBonus_1.SpaceBonus.ENERGY]: 'energy',
    [SpaceBonus_1.SpaceBonus.DATA]: 'data',
    [SpaceBonus_1.SpaceBonus.SCIENCE]: 'science',
    [SpaceBonus_1.SpaceBonus.ENERGY_PRODUCTION]: 'energy-production',
    [SpaceBonus_1.SpaceBonus.TEMPERATURE]: 'bonustemperature',
    [SpaceBonus_1.SpaceBonus.ASTEROID]: 'asteroid',
    [SpaceBonus_1.SpaceBonus.DELEGATE]: 'delegate',
    [SpaceBonus_1.SpaceBonus.COLONY]: 'colony',
    [SpaceBonus_1.SpaceBonus._RESTRICTED]: '',
};
exports.default = vue_1.default.extend({
    name: 'bonus',
    props: {
        bonus: {
            type: Array,
        },
    },
    methods: {
        getClass(idx, bonus) {
            const doubleWideBonuses = [
                SpaceBonus_1.SpaceBonus.OCEAN,
                SpaceBonus_1.SpaceBonus.TEMPERATURE,
                SpaceBonus_1.SpaceBonus.COLONY,
            ];
            let position = idx;
            if (this.bonus.length === 1 && !doubleWideBonuses.includes(bonus)) {
                position = 'only';
            }
            return `board-space-bonus board-space-bonus--${css[bonus]} board-space-bonus-pos--${position}`;
        },
    },
});
