import { render, staticRenderFns } from "./CardTag.vue?vue&type=template&id=6fec4733"
import script from "./CardTag.vue?vue&type=script&lang=ts"
export * from "./CardTag.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports