"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AlliedPartyAgenda_vue_1 = require("@/client/components/turmoil/AlliedPartyAgenda.vue");
exports.default = vue_1.default.extend({
    name: 'PlayerAlliedParty',
    props: {
        player: {
            type: Object,
        },
    },
    components: {
        AlliedPartyAgenda: AlliedPartyAgenda_vue_1.default,
    },
});
