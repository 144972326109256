"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRenderItemComponent_vue_1 = require("@/client/components/card/CardRenderItemComponent.vue");
exports.default = vue_1.default.extend({
    name: 'CardVictoryPoints',
    props: {
        victoryPoints: {
            type: [Number, Object],
            required: true,
        },
    },
    components: {
        CardRenderItemComponent: CardRenderItemComponent_vue_1.default,
    },
    methods: {
        getClasses() {
            const classes = ['card-points'];
            if (this.isObject(this.victoryPoints)) {
                const item = this.victoryPoints;
                if (item.anyPlayer) {
                    classes.push('card-points-big');
                    classes.push('red-outline');
                }
                else {
                    classes.push('card-points-normal');
                }
            }
            else {
                classes.push('card-points-big');
            }
            return classes.join(' ');
        },
        isObject(item) {
            return item.points !== undefined;
        },
        points() {
            if (!this.isObject(this.victoryPoints))
                return '';
            const vps = this.victoryPoints;
            if (vps.item === undefined && vps.points === 0 && vps.target === 0) {
                return '?';
            }
            if (vps.item === undefined) {
                return `${vps.points}`;
            }
            if (vps.target === vps.points || vps.target === 1) {
                return `${vps.points}/`;
            }
            if (vps.asFraction) {
                if (vps.target === 3 && vps.points === 1) {
                    return '⅓';
                }
            }
            return `${vps.points}/${vps.target}`;
        },
    },
});
