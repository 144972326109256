"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Award_vue_1 = require("@/client/components/Award.vue");
const constants_1 = require("@/common/constants");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'Awards',
    components: { Award: Award_vue_1.default },
    props: {
        awards: {
            type: Array,
            required: true,
        },
        showScores: {
            type: Boolean,
            default: false,
        },
        preferences: {
            type: Object,
            default: () => PreferencesManager_1.PreferencesManager.INSTANCE.values(),
        },
    },
    data() {
        return {
            showAwardDetails: this.preferences?.show_award_details,
            showDescription: false,
        };
    },
    methods: {
        toggleList() {
            this.showAwardDetails = !this.showAwardDetails;
            PreferencesManager_1.PreferencesManager.INSTANCE.set('show_award_details', this.showAwardDetails);
        },
        toggleDescription() {
            this.showDescription = !this.showDescription;
        },
    },
    computed: {
        fundedAwards() {
            const isFunded = (award) => !!award.playerName;
            return this.awards.filter(isFunded);
        },
        availableAwardSpots() {
            return constants_1.AWARD_COSTS.slice(this.fundedAwards.length);
        },
        isLearnerModeOn() {
            return this.preferences.learner_mode;
        },
    },
});
