"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
exports.default = vue_1.default.extend({
    name: 'SelectAmount',
    components: {
        AppButton: AppButton_vue_1.default,
    },
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            amount: this.playerinput.maxByDefault ? String(this.playerinput.max) : String(this.playerinput.min),
        };
    },
    methods: {
        saveData() {
            this.onsave({ type: 'amount', amount: parseInt(this.amount) });
        },
        setMaxValue() {
            this.amount = String(this.playerinput.max);
        },
    },
});
