"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const LanguageSelectionDialog_vue_1 = require("@/client/components/LanguageSelectionDialog.vue");
const constants_1 = require("@/common/constants");
exports.default = vue_1.default.extend({
    name: 'LanguageIcon',
    components: {
        'language-selection-dialog': LanguageSelectionDialog_vue_1.default,
    },
    data() {
        return {
            languagePanelOpen: false,
        };
    },
    computed: {
        preferencesManager() {
            return PreferencesManager_1.PreferencesManager.INSTANCE;
        },
        lang() {
            return PreferencesManager_1.PreferencesManager.INSTANCE.values().lang;
        },
        title() {
            const lang = constants_1.LANGUAGES[this.lang];
            return `${lang[0]} (${lang[1]})`;
        },
    },
});
