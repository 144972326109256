"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardName_1 = require("@/common/cards/CardName");
const Resource_1 = require("@/common/Resource");
exports.default = vue_1.default.extend({
    name: 'CardExtraContent',
    props: {
        card: {
            type: Object,
            required: true,
        },
    },
    methods: {
        isMiningTileOnSteel() {
            return this.card.name !== CardName_1.CardName.SPECIALIZED_SETTLEMENT && this.card.bonusResource?.includes(Resource_1.Resource.STEEL);
        },
        isMiningTileOnTitanium() {
            return this.card.name !== CardName_1.CardName.SPECIALIZED_SETTLEMENT && this.card.bonusResource?.includes(Resource_1.Resource.TITANIUM);
        },
        lifeFound() {
            return this.card.name === CardName_1.CardName.SEARCH_FOR_LIFE && this.card.resources !== undefined && this.card.resources > 0;
        },
    },
});
