"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const PurgeWarning_vue_1 = require("@/client/components/common/PurgeWarning.vue");
const utils_1 = require("@/common/utils/utils");
const GameSetupDetail_vue_1 = require("@/client/components/GameSetupDetail.vue");
function copyToClipboard(text) {
    const input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}
const DEFAULT_COPIED_PLAYER_ID = '-1';
exports.default = vue_1.default.extend({
    name: 'game-home',
    props: {
        game: {
            type: Object,
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
        'game-setup-detail': GameSetupDetail_vue_1.default,
        PurgeWarning: PurgeWarning_vue_1.default,
    },
    data() {
        return {
            urlCopiedPlayerId: DEFAULT_COPIED_PLAYER_ID,
        };
    },
    methods: {
        getGameId() {
            return this.game !== undefined ? this.game.id.toString() : 'n/a';
        },
        getTurnOrder(index) {
            if (index === 0) {
                return '1st';
            }
            else if (index === 1) {
                return '2nd';
            }
            else if (index === 2) {
                return '3rd';
            }
            else if (index > 2) {
                return `${index + 1}th`;
            }
            else {
                return 'n/a';
            }
        },
        setCopiedIdToDefault() {
            this.urlCopiedPlayerId = DEFAULT_COPIED_PLAYER_ID;
        },
        getPlayerCubeColorClass(color) {
            return (0, utils_1.playerColorClass)(color.toLowerCase(), 'bg');
        },
        getHref(playerId) {
            if (playerId === this.game.spectatorId) {
                return `spectator?id=${playerId}`;
            }
            return `player?id=${playerId}`;
        },
        copyUrl(playerId) {
            if (playerId === undefined)
                return;
            const path = window.location.href.replace(/game\?id=.*/, '');
            copyToClipboard(path + this.getHref(playerId));
            this.urlCopiedPlayerId = playerId;
        },
        isPlayerUrlCopied(playerId) {
            return playerId === this.urlCopiedPlayerId;
        },
    },
});
