"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const Units_1 = require("@/common/Units");
const PaymentUnit_vue_1 = require("@/client/components/PaymentUnit.vue");
const utils_1 = require("@/common/utils/utils");
exports.default = vue_1.default.extend({
    name: 'SelectResource',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            units: { ...Units_1.Units.EMPTY },
        };
    },
    components: {
        AppButton: AppButton_vue_1.default,
        PaymentUnitComponent: PaymentUnit_vue_1.default,
    },
    methods: {
        saveData() {
            this.onsave({ type: 'resources', units: this.units });
        },
        reduceValue(unit) {
            const currentValue = this.units[unit];
            if (currentValue === undefined) {
                throw new Error(`can not reduceValue for ${unit} on this`);
            }
            const adjustedDelta = Math.min(1, currentValue);
            if (adjustedDelta === 0)
                return;
            this.units[unit] -= adjustedDelta;
        },
        addValue(unit) {
            const currentValue = this.units[unit];
            if (currentValue === undefined) {
                throw new Error(`can not addValue for ${unit} on this`);
            }
            if ((0, utils_1.sum)(Units_1.Units.values(this.units)) >= this.playerinput.count) {
                return;
            }
            this.units[unit] += 1;
        },
    },
    computed: {
        keys() {
            return Units_1.Units.keys;
        },
    },
});
