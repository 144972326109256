"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const LogMessageDataType_1 = require("@/common/logs/LogMessageDataType");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const utils_1 = require("@/common/utils/utils");
exports.default = vue_1.default.extend({
    name: 'log-panel',
    props: {
        message: Object,
        players: Array,
    },
    components: {
        AppButton: AppButton_vue_1.default,
        Card: Card_vue_1.default,
        Colony: Colony_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
    },
    computed: {
        show() {
            return this.cards.length + this.globalEvents.length + this.colonies.length > 0;
        },
        cards() {
            if (this.message === undefined) {
                return [];
            }
            const entries = this.message.data.map((datum) => datum.type === LogMessageDataType_1.LogMessageDataType.CARD ? datum.value : undefined);
            return (0, utils_1.deNull)(entries);
        },
        globalEvents() {
            if (this.message === undefined) {
                return [];
            }
            const entries = this.message.data.map((datum) => datum.type === LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT ? datum.value : undefined);
            return (0, utils_1.deNull)(entries);
        },
        colonies() {
            if (this.message === undefined) {
                return [];
            }
            const entries = this.message.data.map((datum) => datum.type === LogMessageDataType_1.LogMessageDataType.COLONY ? datum.value : undefined);
            return (0, utils_1.deNull)(entries);
        },
    },
    methods: {
        hideMe() {
            this.$emit('hide');
        },
        getColony(name) {
            return {
                colonies: [],
                isActive: false,
                name: name,
                trackPosition: 0,
                visitor: undefined,
            };
        },
        isSelfReplicatingRobotsCard(cardName) {
            for (const player of this.players) {
                if (player.selfReplicatingRobotsCards.some((card) => card.name === cardName)) {
                    return true;
                }
            }
            return false;
        },
        getResourcesOnCard(cardName) {
            for (const player of this.players) {
                const playedCard = player.tableau.find((card) => card.name === cardName);
                if (playedCard !== undefined) {
                    return playedCard.resources;
                }
                const srrCard = player.selfReplicatingRobotsCards.find((card) => card.name === cardName);
                if (srrCard !== undefined) {
                    return srrCard.resources;
                }
            }
            return undefined;
        },
    },
});
