"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const SpaceType_1 = require("@/common/boards/SpaceType");
const MoonSpace_vue_1 = require("@/client/components/moon/MoonSpace.vue");
exports.default = vue_1.default.extend({
    name: 'MoonBoard',
    props: {
        model: {
            type: Object,
        },
        tileView: {
            type: String,
            default: 'show',
        },
    },
    components: {
        MoonSpace: MoonSpace_vue_1.default,
    },
    methods: {
        getAllNonColonySpaces() {
            const boardSpaces = [...this.model.spaces];
            boardSpaces.sort((space1, space2) => {
                return parseInt(space1.id) - parseInt(space2.id);
            });
            return boardSpaces.filter((s) => {
                return s.spaceType !== SpaceType_1.SpaceType.COLONY;
            });
        },
        getSpaceById(spaceId) {
            for (const space of this.model.spaces) {
                if (space.id === spaceId) {
                    return space;
                }
            }
            throw new Error('Board space not found by id \'' + spaceId + '\'');
        },
        getValuesForParameter(targetParameter) {
            let curValue;
            switch (targetParameter) {
                case 'logistics':
                    curValue = this.model.logisticsRate;
                    break;
                case 'mining':
                    curValue = this.model.miningRate;
                    break;
                case 'habitat':
                    curValue = this.model.habitatRate;
                    break;
                default:
                    throw new Error('Wrong parameter to get values from: ' + targetParameter);
            }
            const values = [];
            for (let value = 8; value >= 0; value -= 1) {
                values.push({
                    value: value,
                    isActive: value === curValue,
                    strValue: value.toString(),
                });
            }
            return values;
        },
        getScaleCSS(paramLevel) {
            let css = 'global-numbers-value val-' + paramLevel.value + ' ';
            if (paramLevel.value === 0) {
                css += 'zero-gap ';
            }
            if (paramLevel.isActive) {
                css += 'val-is-active';
            }
            return css;
        },
    },
});
