"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ICardRenderDescription_1 = require("@/common/cards/render/ICardRenderDescription");
exports.default = vue_1.default.extend({
    name: 'CardDescription',
    props: {
        item: {
            required: true,
        },
    },
    computed: {
        classes() {
            const classes = ['card-description'];
            if ((0, ICardRenderDescription_1.isIDescription)(this.item)) {
                if (this.item.align !== 'center') {
                    classes.push('card-description-aligned');
                }
                classes.push('card-description-align--' + this.item.align);
            }
            return classes;
        },
        description() {
            return (0, ICardRenderDescription_1.isIDescription)(this.item) ? this.item.text : String(this.item);
        },
    },
});
