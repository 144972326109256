"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
exports.default = vue_1.default.extend({
    name: 'SelectGlobalEvent',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
            required: false,
            default: false,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            selected: undefined,
        };
    },
    components: {
        GlobalEvent: GlobalEvent_vue_1.default,
        AppButton: AppButton_vue_1.default,
    },
    methods: {
        saveData() {
            if (this.selected === undefined) {
                throw new Error('Select a global event');
            }
            this.onsave({ type: 'globalEvent', globalEventName: this.selected });
        },
    },
});
