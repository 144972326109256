"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CARD_FOR_SPENDABLE_RESOURCE = exports.SPENDABLE_RESOURCES = exports.OTHER_SPENDABLE_RESOURCES = exports.SPENDABLE_CARD_RESOURCES = exports.SPENDABLE_STANDARD_RESOURCES = void 0;
const CardName_1 = require("../cards/CardName");
exports.SPENDABLE_STANDARD_RESOURCES = [
    'megaCredits',
    'heat',
    'steel',
    'titanium',
    'plants',
];
exports.SPENDABLE_CARD_RESOURCES = [
    'microbes',
    'floaters',
    'lunaArchivesScience',
    'spireScience',
    'seeds',
    'auroraiData',
    'graphene',
    'kuiperAsteroids',
];
exports.OTHER_SPENDABLE_RESOURCES = [
    'corruption',
];
exports.SPENDABLE_RESOURCES = [...exports.SPENDABLE_STANDARD_RESOURCES, ...exports.SPENDABLE_CARD_RESOURCES, ...exports.OTHER_SPENDABLE_RESOURCES];
exports.CARD_FOR_SPENDABLE_RESOURCE = {
    microbes: CardName_1.CardName.PSYCHROPHILES,
    floaters: CardName_1.CardName.DIRIGIBLES,
    lunaArchivesScience: CardName_1.CardName.LUNA_ARCHIVES,
    spireScience: CardName_1.CardName.SPIRE,
    seeds: CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS,
    auroraiData: CardName_1.CardName.AURORAI,
    graphene: CardName_1.CardName.CARBON_NANOSYSTEMS,
    kuiperAsteroids: CardName_1.CardName.KUIPER_COOPERATIVE,
};
