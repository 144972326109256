"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardName_1 = require("@/common/cards/CardName");
const PlayerResource_vue_1 = require("@/client/components/overview/PlayerResource.vue");
const Resource_1 = require("@/common/Resource");
exports.default = vue_1.default.extend({
    name: 'PlayerResources',
    props: {
        player: {
            type: Object,
        },
    },
    computed: {
        Resource() {
            return Resource_1.Resource;
        },
        canUseHeatAsMegaCredits() {
            return this.player.tableau.some((card) => card.name === CardName_1.CardName.HELION);
        },
    },
    components: {
        'player-resource': PlayerResource_vue_1.default,
    },
});
