"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants_1 = require("@/common/constants");
exports.default = vue_1.default.extend({
    name: 'MoonGlobalParameterValue',
    props: {
        moonData: {
            type: Object,
        },
    },
    computed: {
        isMax() {
            return (this.moonData.habitatRate >= constants_1.MAXIMUM_HABITAT_RATE &&
                this.moonData.miningRate >= constants_1.MAXIMUM_MINING_RATE &&
                this.moonData.logisticsRate >= constants_1.MAXIMUM_LOGISTICS_RATE);
        },
    },
});
