"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
exports.default = vue_1.default.extend({
    name: 'SelectResource',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            unit: undefined,
        };
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    methods: {
        canSave() {
            return this.unit !== undefined;
        },
        saveData() {
            if (this.unit === undefined) {
                return;
            }
            this.onsave({ type: 'resource', resource: this.unit });
        },
    },
});
