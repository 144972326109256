"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants = require("@/common/constants");
const GameOverview_vue_1 = require("@/client/components/admin/GameOverview.vue");
const statusCode_1 = require("@/common/http/statusCode");
exports.default = vue_1.default.extend({
    name: 'games-overview',
    data() {
        return {
            entries: [],
        };
    },
    mounted() {
        this.getGames();
    },
    components: {
        GameOverview: GameOverview_vue_1.default,
    },
    methods: {
        getGames() {
            const vueApp = this;
            const xhr = new XMLHttpRequest();
            xhr.open('GET', 'api/games?serverId=' + this.serverId);
            xhr.onerror = function () {
                alert('Error getting games data');
            };
            xhr.onload = () => {
                if (xhr.status === statusCode_1.statusCode.ok) {
                    const result = xhr.response;
                    if (result instanceof Array) {
                        result.forEach(function (response) {
                            vueApp.entries.push({
                                id: response.gameId,
                                game: undefined,
                                status: 'loading'
                            });
                        });
                        vueApp.getGame(0);
                        return;
                    }
                }
                alert('Unexpected response fetching games from API');
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        getGame(idx) {
            if (idx >= this.entries.length) {
                return;
            }
            const entry = this.entries[idx];
            const gameId = entry.id;
            const xhr = new XMLHttpRequest();
            xhr.open('GET', 'api/game?id=' + gameId);
            xhr.onerror = () => {
                entry.status = 'error';
                this.getGame(idx + 1);
            };
            xhr.onload = () => {
                if (xhr.status === statusCode_1.statusCode.ok) {
                    const result = xhr.response;
                    if (result instanceof Object) {
                        const game = result;
                        entry.status = 'done';
                        entry.game = game;
                        this.getGame(idx + 1);
                        return;
                    }
                }
                entry.status = 'error';
                this.getGame(idx + 1);
            };
            xhr.responseType = 'json';
            xhr.send();
        },
    },
    computed: {
        constants() {
            return constants;
        },
        serverId() {
            return (new URL(location.href)).searchParams.get('serverId') || '';
        },
    },
});
