"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Timer_1 = require("@/common/Timer");
exports.default = vue_1.default.extend({
    name: 'PlayerTimer',
    props: {
        timer: {
            type: Object,
        },
        live: {
            type: Boolean,
        },
    },
    data() {
        return {
            timerText: '',
        };
    },
    mounted() {
        this.updateTimer();
    },
    watch: {
        timerText: {
            handler() {
                if (this.live) {
                    setTimeout(() => {
                        this.updateTimer();
                    }, 1000);
                }
            },
        },
    },
    methods: {
        updateTimer() {
            this.timerText = Timer_1.Timer.toString(this.timer);
        },
        hasHours() {
            if (this.timerText.split(':').length > 2) {
                return 1;
            }
            return 0;
        },
        getHours() {
            if (this.hasHours()) {
                return this.timerText.split(':')[0];
            }
            return '';
        },
        getMinutes() {
            if (this.hasHours()) {
                return this.timerText.split(':')[1];
            }
            return this.timerText.split(':')[0];
        },
        getSeconds() {
            if (this.hasHours()) {
                return this.timerText.split(':')[2];
            }
            return this.timerText.split(':')[1];
        },
    },
});
