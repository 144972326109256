"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRequirementComponent_vue_1 = require("./CardRequirementComponent.vue");
exports.default = vue_1.default.extend({
    name: 'CardRequirementsComponent',
    props: {
        requirements: {
            type: (Array),
            required: true,
        },
    },
    components: {
        'card-requirement': CardRequirementComponent_vue_1.default,
    },
    computed: {
        getClasses() {
            const hasMax = this.requirements.some((req) => req.max);
            return hasMax ? 'card-requirements card-requirements-max' : 'card-requirements';
        },
        indentRight() {
            return [false, ...this.requirements.map((req) => (req.nextTo || false))];
        },
    },
});
