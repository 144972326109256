"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequirementType = void 0;
var RequirementType;
(function (RequirementType) {
    RequirementType["OXYGEN"] = "O2";
    RequirementType["TEMPERATURE"] = "C";
    RequirementType["OCEANS"] = "Ocean";
    RequirementType["TR"] = "TR";
    RequirementType["RESOURCE_TYPES"] = "Resource type";
    RequirementType["GREENERIES"] = "Greenery";
    RequirementType["CITIES"] = "City";
    RequirementType["TAG"] = "tag";
    RequirementType["PRODUCTION"] = "production";
    RequirementType["REMOVED_PLANTS"] = "Removed plants";
    RequirementType["VENUS"] = "Venus";
    RequirementType["COLONIES"] = "Colony";
    RequirementType["FLOATERS"] = "Floater";
    RequirementType["CHAIRMAN"] = "Chairman";
    RequirementType["PARTY_LEADERS"] = "Party leader";
    RequirementType["PARTY"] = "party";
    RequirementType["HABITAT_RATE"] = "Habitat rate";
    RequirementType["MINING_RATE"] = "Mining rate";
    RequirementType["LOGISTIC_RATE"] = "Logistic rate";
    RequirementType["HABITAT_TILES"] = "Habitat tiles";
    RequirementType["MINING_TILES"] = "Mine tiles";
    RequirementType["ROAD_TILES"] = "Road tiles";
    RequirementType["EXCAVATION"] = "Excavation";
    RequirementType["CORRUPTION"] = "Corruption";
})(RequirementType = exports.RequirementType || (exports.RequirementType = {}));
