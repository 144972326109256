"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Bonus_vue_1 = require("@/client/components/Bonus.vue");
const BoardSpaceTile_vue_1 = require("@/client/components/board/BoardSpaceTile.vue");
exports.default = vue_1.default.extend({
    name: 'MoonSpace',
    props: {
        space: {
            type: Object,
        },
        text: {
            type: String,
            required: false,
        },
        tileView: {
            type: String,
            default: 'show',
        },
    },
    components: {
        Bonus: Bonus_vue_1.default,
        'board-space-tile': BoardSpaceTile_vue_1.default,
    },
    computed: {
        mainClass() {
            let css = 'board-space moon-space-' + this.space.id.toString();
            css += ' board-space-selectable';
            if (this.space.spaceType === 'lunar_mine') {
                css += ' moon-space-type-mine';
            }
            else {
                css += ' moon-space-type-other';
            }
            return css;
        },
    },
});
