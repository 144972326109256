"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'underground-resources',
    props: {
        space: {
            type: Object,
        },
        tileView: {
            type: String,
        },
    },
    computed: {
        resourceClass() {
            return 'underground-token-resource underground-token--' + this.space.undergroundResources;
        },
        excavatorClass() {
            return ' underground-excavator underground-excavator--' + this.space.excavator;
        },
    },
});
