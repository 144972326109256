"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'PointsPerTag',
    props: {
        points: {
            type: Object,
        },
    },
    computed: {
        amount() {
            if (this.points.halfPoints === 2) {
                return '2⁄2';
            }
            const points = this.points.points + (this.points.halfPoints / 2);
            const integer = Math.floor(points);
            const fraction = points - integer;
            let vulgarFraction = '';
            if (fraction === 0.5) {
                vulgarFraction = '½';
            }
            else if (Math.abs(fraction - (1 / 3)) < Number.EPSILON) {
                vulgarFraction = '⅓';
            }
            return `${integer || ''}${vulgarFraction}`;
        },
        cssClasses() {
            if (this.points.halfPoints === 2) {
                return 'points-per-tag points-per-tag--XS';
            }
            return this.amount.length === 1 ?
                'points-per-tag' :
                'points-per-tag points-per-tag--S';
        },
        show() {
            return this.points.points !== 0 || this.points.halfPoints !== 0;
        },
    },
});
