"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const descriptions = {
    'maxtemp': 'Note: the temperature is already at its goal.',
    'maxoxygen': 'Note: the oxygen level is already at its goal.',
    'maxoceans': 'Note: all oceans are already on the board.',
    'maxvenus': 'Note: Venus scale is already at its goal.',
    'maxHabitatRate': 'Note: Moon habitat rate is already at its goal.',
    'maxMiningRate': 'Note: Moon mining rate is already at its goal.',
    'maxLogisticsRate': 'Note: Moon logistics rate is already at its goal.',
    'decreaseOwnProduction': 'Warning: you are the only player that can lose production.',
    'removeOwnPlants': 'Warning: this will remove your own plants',
    'buildOnLuna': 'You will only be able to build the colony on Luna.',
    'preludeFizzle': 'This prelude is not playable, so you will discard it and gain 15 M€.',
    'underworldMustExcavateEnergy': 'You will be limited to excavating a space that gives 1 energy production.',
    'deckTooSmall': 'There are not enough cards to complete this action. You will draw fewer cards than expected.',
    'cannotAffordBoardOfDirectors': 'Warning: you do not have the 12 M€ required to act on a prelude.',
    'marsIsTerraformed': 'Note: all global parameters are at their goals.',
};
exports.default = vue_1.default.extend({
    name: 'WarningsComponent',
    props: {
        warnings: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        descriptions() {
            return descriptions;
        },
    },
});
